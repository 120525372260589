import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/elements/Button';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import {
  nextStep,
  selectIsNextDisabled,
} from '@features/orderTabs/orderTabsSlice';
import { useAppMode } from '@hooks/useAppConfigSelectors';

const BasketAction = ({
  buttonNextText = null,
  isMutatingBasket,
  currentDiet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDietFull } = useAppMode();
  const { dietLength, deliveryDates = [] } = currentDiet || {};

  const isFullCalendarValid = !isDietFull
    ? true
    : deliveryDates?.length === dietLength;

  const isNextDisabled = useSelector(selectIsNextDisabled);
  const isNextStepValid =
    isFullCalendarValid && !isNextDisabled && !isMutatingBasket;

  const handleNextStep = () => {
    dispatch(nextStep());
    window.scrollTo({ top: 0 });
  };

  if (!isFullCalendarValid) {
    return (
      <Tooltip content={t('$*components.basket.dietLengthIsInvalid')}>
        <Button
          fullWidth
          onClick={handleNextStep}
          isLoading={isMutatingBasket}
          disabled={!isNextStepValid}
          data-cy="basket-action__button--next"
          id="order-form-next-button"
        >
          {buttonNextText ?? t('$*components.basket.next')}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      fullWidth
      onClick={handleNextStep}
      isLoading={isMutatingBasket}
      disabled={!isNextStepValid}
      data-cy="basket-action__button--next"
      id="order-form-next-button"
    >
      {buttonNextText ?? t('$*components.basket.next')}
    </Button>
  );
};

export default withMemoBasketMethods(BasketAction, [
  'isMutatingBasket',
  'currentDiet',
]);
